<template>
  <svg class="icon" :class="`icon--${id}`">
    <use :xlink:href="`${ENP.CONFIG.paths.static}images/icons.svg#${id}`" />
  </svg>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    className: {
      type: String,
      default: null,
    },
  },
};
</script>
